<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="content_margin_bottom">
      <div class="row">
        <div class="col-md-12 mb-4">
          <h4 class="card-title my-3 float-right">
            <router-link
              :to="`/entity/${entityId}/course/${courseId}`"
              class="btn btn-success btn-sm"
              >Course Details</router-link
            >
          </h4>
        </div>
      </div>
      <section>
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div>
              <div class="card">
                <div class="card-body">
                  <div class="form-group">
                    <label for="title">Name</label>
                    <input
                      type="text"
                      placeholder="Enter Course Title"
                      id="title"
                      class="form-control"
                      v-model="content.unit_name"
                    />
                  </div>
                  <div class="form-group">
                    <label for="overview">Description</label>
                    <textarea
                      type="text"
                      placeholder="Enter course description"
                      id="overview"
                      class="form-control"
                      v-model="content.description"
                    />
                  </div>
                  <div class="form-group" v-if="isFile == 'file'">
                    <label for="avatar">File</label>
                    <input
                      type="file"
                      id="avatar"
                      accept="application/pdf,application/vnd.ms-excel"
                      ref="file"
                      @change="contentFile"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group" v-if="isFile == 'article'">
                    <label for="avatar">File</label>
                    <input
                      type="file"
                      id="avatar"
                      accept="application/pdf,application/vnd.ms-excel"
                      ref="file"
                      @change="contentFile"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group" v-if="isFile == 'video'">
                    <label for="avatar">Video Url</label>
                    <input
                      type="text"
                      placeholder="Enter video url"
                      id="avatar"
                      class="form-control"
                      v-model="content.url"
                    />
                  </div>
                  <div class="form-group">
                    <label for="name">Active</label>
                    <div class="d-flex ">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="form-check"
                          v-model="content.active"
                          id="inliner"
                          value="true"
                        />
                        <label class="form-check-label" for="inliner"
                          >True</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="form-check"
                          v-model="content.active"
                          id="inlineRadio2"
                          value="false"
                        />
                        <label class="form-check-label" for="inlineRadio2"
                          >False</label
                        >
                      </div>
                    </div>
                  </div>

                  <div class="row justify-content-end">
                    <div class="py-2 py-3">
                      <button
                        @click.prevent="updateContent"
                        class="btn btn-primary btn-block my-btn float-right"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
</template>

<script>
/* eslint-disable no-unused-vars */
import store from "@/state/store";
import toasterMixin from "@/mixins/toasterMixin.js";
import Layout from "../../layout/index.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

export default {
  page: {
    title: "Edit Content",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    PageHeader,
    Layout,
  },
  data() {
    return {
      title: "Edit Content",
      items: [
        {
          text: "Entity Admin",
        },
        {
          text: "Edit Content",
          active: true,
        },
      ],
      entityId: this.$route.params.id,
      courseId: this.$route.params.courseId,
      contentId: this.$route.params.contentId,
      error: null,
      success: null,
      loading: false,
      content: {
        unit_name: "",
        description: "",
        active: Boolean(""),
        url: "",
        file: "",
        id: null,
      },
      isFile: "",
      showType: ["video", "file", "article"],
      testId: null,
    };
  },
  computed: {
    isComplete() {
      return (
        this.course.title != "" &&
        this.course.overview != "" &&
        this.course.catalogue != ""
      );
    },
  },
  methods: {
    contentFile(e) {
      const selectedFile = e.target.files[0];
      this.content.file = selectedFile;
    },
    updateContent() {
      this.loading = true;
      this.error = null;
      this.success = null;
      let formData = new FormData();

      if (this.isFile == "video") {
        formData.append("entity_id", parseInt(this.$route.params.id));
        formData.append("unit_name", this.content.unit_name);
        formData.append("description", this.content.description);
        formData.append("active", this.content.active);
        formData.append("url", this.content.url);

        const data = {
          content: formData,
          contentId: this.testId,
          contentType: this.isFile,
        };

        console.log(data, "content");
        console.log(this.isFile, "video");

        // DISPATCHING DATA TO THE BACKEND
        store
          .dispatch("contents/updateContent", data)
          .then((response) => {
            console.log(response);
            if (response.status == 200) {
              console.log(response);
              this.loading = false;
              this.toast({
                message: "Content Updated",
                type: "success",
              });
              this.$router.push(
                `/entity/${this.entityId}/course/${this.courseId}`
              );
            }
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            if (err?.response?.data == undefined) {
              this.loading = false;
              this.toast({
                message:
                  "Network error. Check your network connection and try again.",
                type: "error",
              });
            }
          });
      } else if (this.isFile == "file" || this.isFile == "article") {
        formData.append("entity_id", parseInt(this.$route.params.id));
        formData.append("unit_name", this.content.unit_name);
        formData.append("description", this.content.description);
        formData.append("active", this.content.active);
        if (this.content.file) {
          formData.append("file", this.content.file);
        }

        const data = {
          content: formData,
          contentId: this.testId,
          contentType: this.isFile,
        };

        console.log(data, "content");
        console.log(this.isFile, "file");
        // DISPATCHING DATA TO THE BACKEND
        store
          .dispatch("contents/updateContent", data)
          .then((response) => {
            if (response.status == 200) {
              this.loading = false;
              this.toast({ message: "Content Updated", type: "success" });
              this.$router.push(
                `/entity/${this.entityId}/course/${this.courseId}`
              );
            }
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            if (err?.response?.data == undefined) {
              this.loading = false;
              this.toast({
                message:
                  "Network error. Check your network connection and try again.",
                type: "error",
              });
            }
          });
      }
    },
  },
  created() {
    store
      .dispatch("course/fetchCourses", this.$route.params.id)
      .then((response) => {
        const res = response.data.course;
        const course = res.find(
          (course) => course.id == this.$route.params.courseId
        );

        const testType = course.contents;

        for (let i = 0; i < testType.length; i++) {
          const content = testType[i].content;
          console.log(content);
          if (testType[i].id == this.contentId) {
            this.isFile = testType[i].content_type.model;
            console.log(this.isFile);
            const file = testType[i].content;
            this.testId = testType[i].id;
            this.content.unit_name = file.unit_name;
            this.content.description = file.description;
            this.content.active = file.active;
            this.content.id = file.id;
            this.content.url = file.url;
            this.content.file = null;
          }
        }
        this.loading = false;
        this.success = true;
      });
  },
};
</script>

<style scoped>
label {
  color: #000;
}

.table-wrapper {
  background: #fff;
  padding: 20px 25px;
  margin: 30px 0;
  border-radius: 2px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.247);
}
.table-title {
  padding-bottom: 15px;
  background: linear-gradient(40deg, #ff2020, #05ffa3);
  color: #fff;
  padding: 16px 30px;
  margin: -20px -25px 10px;
  border-radius: 1px 1px 0 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.247);
}
.table-title h2 {
  margin: 5px 0 0;
  font-size: 24px;
}
.my-btn {
  width: 150px;
}
fieldset {
  width: inherit;
  padding: 0 10px;
  border-bottom: none;
}
</style>
